import {createStore, combineReducers, applyMiddleware} from 'redux';
import UserReducer from "./reducer/UserReducer";
import ExportReducer from "./reducer/ExportReducer";
import {thunk} from "redux-thunk";

const rootReducer = combineReducers({
  user: UserReducer,
  export: ExportReducer,
});

const configureStore = () => {
  return createStore(
      rootReducer,
      applyMiddleware(thunk)
  );
};

export default configureStore;

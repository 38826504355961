import React, {memo, useEffect} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useDispatch, useSelector} from "react-redux";
import {addTaskToExport, fetchExportType} from "../../storage/actions/ExportActions";
import {API_URL} from "../../utils/consts";
import axiosInstance from "../../utils/apiCalls";
import fileDownload from "js-file-download";

function ExportExcel({ exportType, totalDeals = null, filterData = {} }) {
    const dispatch = useDispatch();

    const exportList = useSelector((state) => state.export.exportList);
    const exportCount = useSelector((state) => state.export.exportCount);
    const exportDoneCount = useSelector((state) => state.export.exportDoneCount);
    const exportItems = useSelector((state) => state.export.exportItems);

    useEffect(() => {
        dispatch(fetchExportType(exportType));
    }, [dispatch]);

    const handleAddTaskToExport = (type = null) => {
        dispatch(addTaskToExport(exportType, type, totalDeals, filterData));
    };

    const handleDownloadFile = (fileId) => {
        axiosInstance.get(`${API_URL}/export/download`, {
            params: { id: fileId },
            responseType: 'blob',
        })
            .then(response => {
                dispatch(fetchExportType(exportType))
                return response.data;
            })
            .then(fileData => fileDownload(fileData, `${exportType}.xls`));
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Экспортировать {exportCount} / {exportDoneCount}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleAddTaskToExport()}>Сформировать выгрузку</Dropdown.Item>
                {exportList.map(item => (
                    <Dropdown.Item key={item.type} onClick={() => handleAddTaskToExport(item.type)}>
                        Сформировать выгрузку {item.value}
                    </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                {exportItems.map(exportItem => (
                    <Dropdown.Item
                        key={exportItem.id}
                        disabled={!exportItem.status}
                        onClick={() => handleDownloadFile(exportItem.id)}>
                        {exportItem.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default memo(ExportExcel);

import {fetchExportList, handleExportJob} from "../storage/actions/ExportActions"; // Import other actions as needed

export function handleWebSocketMessage(dispatch, data) {
    const { action, message } = data;

    switch (action) {
        case "EXPORT_JOB":
            fetchExportList(message.unique_type)(dispatch);
            break;
        // Add cases for other action types here
        default:
            console.warn("Unknown WebSocket action:", action);
    }
}

import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

function HomeScreen({user}) {
    return (
        <div className="bg-light p-5 rounded">
            <h1>Navbar example</h1>
            <p className="lead">This example is a quick exercise to illustrate how fixed to top navbar works. As you scroll, it will remain fixed to the top of your browser’s viewport.</p>
            <NavLink to={'/deals'} className="btn btn-lg btn-primary" role="button">Список сделок</NavLink>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

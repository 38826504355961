import React from "react";

function ErrorComponent({message, code}) {
    return (
        <div className="bg-light p-5 rounded">
            <h1>Ошибка: {message}</h1>
            <h2>Код ошибки: {code}</h2>
        </div>
    );
};

export default ErrorComponent;

import {EXPORT_JOB} from "../../utils/consts";
import { API_URL } from "../../utils/consts";
import axiosInstance from "../../utils/apiCalls";

export const handleExportJob = (exportData) => ({
    type: EXPORT_JOB,
    payload: exportData,
});

export const SET_EXPORT_LIST = "SET_EXPORT_LIST";
export const SET_EXPORT_COUNTS = "SET_EXPORT_COUNTS";
export const SET_EXPORT_ITEMS = "SET_EXPORT_ITEMS";

export const setExportList = (data) => ({
    type: SET_EXPORT_LIST,
    payload: data,
});

export const setExportCounts = (counts) => ({
    type: SET_EXPORT_COUNTS,
    payload: counts,
});

export const setExportItems = (items) => ({
    type: SET_EXPORT_ITEMS,
    payload: items,
});

export const fetchExportType = (exportType) => (dispatch) => {
    axiosInstance.get(`${API_URL}/dictionary/export-list`)
        .then(response => dispatch(setExportList(response.data.data)))
        .finally(() => dispatch(fetchExportList(exportType)));
};

export const fetchExportList = (exportType) => (dispatch) => {
    console.log(exportType)
    axiosInstance.get(`${API_URL}/export/list`, {
        params: { uniqueType: exportType }
    })
        .then(response => {
            const data = response.data.data;
            dispatch(setExportCounts({ count: data.count, countDone: data.countDone }));
            dispatch(setExportItems(data.items));
        });
};

export const addTaskToExport = (
    exportType,
    type = null,
    totalDeals = null,
    data = {
        DealSearch: {
            contract_code: '2024'
        }
    }
) => (dispatch) => {
    axiosInstance.post(`${API_URL}/export/add-task`, data, {
        params: { uniqueType: exportType, type, maxCount: 1000, totalCount: totalDeals },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
        .finally(() => dispatch(fetchExportList(exportType)));
};

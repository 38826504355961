import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../../utils/consts";
import ErrorComponent from "../../components/error/ErrorComponent";
import {useForm} from "react-hook-form";
import InputWrapper from "../../components/inputWrapper/InputWrapper";
import TextAreaWrapper from "../../components/textAreaWrapper/TextAreaWrapper";
import SelectWithFormHook from "../../components/selectWithFormHook/SelectWithFormHook";
import 'react-datepicker/dist/react-datepicker.css';
import InputDateWrapper from "../../components/datePickerWrapper/InputDateWrapper";
import {StyledDropzone} from "../../components/styledDropzone/StyledDropzone";
import {findReplaceString, getColClass} from "../../utils/helpers";
import fileDownload from "js-file-download";
import {Modal} from 'bootstrap';
import axiosInstance from "../../utils/apiCalls";
import InputMaskWrapper from "../../components/inputWrapper/InputMaskWrapper";
import {BankSuggestions} from "react-dadata";
import InputDaDataWrapper from "../../components/inputWrapper/InputDaDataWrapper";
import DetailView from "../../components/deal/DetailView";

function DealUpdateScreen({user}) {
    const navigate = useNavigate();

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [nextStatusId, setNextStatusId] = useState(null)
    const [backStatusId, setBackStatusId] = useState(null)
    const [backButton, setBackButton] = useState({})
    const [nextButton, setNextButton] = useState({})
    const [cancelButton, setCancelButton] = useState({})
    const [deal, setDeal] = useState({})
    const [detail, setDetail] = useState({})
    const [errorForm, setErrorForm] = useState({})

    const inputMaskFields = [
        {title: 'inputMask:ru:passport:series', mask: '9999'},
        {title: 'inputMask:ru:passport:number', mask: '999999'},
        {title: 'inputMask:ru:passport:subdivision_code', mask: '999-999'},
        {title: 'inputMask:ru:phone', mask: '+9(999)999-99-99'}
    ];

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
        control,
        setError

    } = useForm({
        defaultValues: {},
    })

    useEffect(() => {
        if (id) {
            fetchDeal(id)
        }
    }, [id]);


    const fetchDeal = (id) => {
        setIsLoading(true);
        window.scrollTo({
            top: 0,
        })
        axiosInstance.get(API_URL + '/deals/detail', {
            params: {
                id,
            }
        })
            .then(response => response.data)
            .then(response => {
                for (let key in response.data.deal.dealInfo) {
                    setValue("Deal." + key, response.data.deal.dealInfo[key]);
                }
                setDeal(response.data.deal)
                setDetail(response.data.detail)
                setNextStatusId(response.data.nextStatus)
                setBackStatusId(response.data.backStatus)
                setNextButton(response.data.nextButton)
                setBackButton(response.data.backButton)
                setCancelButton(response.data.cancelButton)
            })
            .catch(errorResponse => {
                setErrorForm({
                    message: errorResponse.response.data.errors.message,
                    code: errorResponse.response.status
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const _getErrors = (columnName) => {
        if (errors.Deal && columnName in errors.Deal) {
            return errors.Deal[columnName];
        }
    }

    const _getAttributesByName = (name) => {
        const values = getValues();

        for (let attribute in values.Deal) {
            if (attribute === name) {
                return values.Deal[attribute];
            }
        }
    }


    const handleForm = (data) => {
        setIsLoading(true)
        axiosInstance.post(API_URL + '/deals/update', {
            DynamicModel: data.Deal,
            Deal: {
                project_workflow_status_id: nextStatusId,
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                id: deal.id,
            }
        })
            .catch(errorResponse => {
                errorResponse.response.data.errors.map(error => {
                    console.log(`Deal.${error.name}`)
                    setError(`Deal.${error.name}`, {
                        type: "random",
                        message: error.message,
                    })
                })
            }).finally(() => fetchDeal(deal.id))
    }

    const cancel = () => {
        setIsLoading(true)
        axiosInstance.post(API_URL + '/deals/update', {
            Deal: {
                project_workflow_status_id: backStatusId,
            }
        }, {
            headers: {
                Authorization: `Bearer ${user.token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                id: deal.id,
            }
        })
            .then(response => response.data)
            .catch(errorResponse => {
                errorResponse.response.data.errors.map(error => {
                    console.log(`Deal.${error.name}`)
                    setError(`Deal.${error.name}`, {
                        type: "random",
                        message: error.message,
                    })
                })
            }).finally(() => fetchDeal(deal.id))
    }

    const _getValueForDropdown = (options, fieldName) => {
        for (let option in options) {
            if (options[option].value === _getAttributesByName(fieldName)) {
                return options[option];
            }
        }
    }

    const _renderRows = (rows) => {
        return rows.map(row => {
            return (
                <div className={'row'} key={row.id}>
                    {_renderCols(row.col)}
                </div>
            );
        })
    }

    const _downloadFile = (url) => {
        axiosInstance.get(API_URL + url, {
            responseType: 'blob',
        })
            .then(response => fileDownload(response.data, 'cert_cet.pdf'))
    }

    const renderInputMaskWrapper = (field, mask) => (
        <InputMaskWrapper
            isVisible={field.type?.title !== 'hidden'}
            errors={_getErrors(field.projectField.name)}
            key={field.id}
            id={field.projectField.name}
            register={register}
            readOnly={field.type?.title === 'readonly'}
            disabled={field.type?.title === 'disabled'}
            setValue={setValue}
            isDatetime={false}
            mask={mask}
            label={field.projectField.label}
            isRequired={true}
        />
    );

    const _renderCols = (cols) => {
        return cols.map(col => {
            return (
                <div className={getColClass(col)} key={col.id}>
                    {col.fields.map(field => {
                        console.log(field.projectField.widget[0]?.fieldWidget?.title)
                        if (field.projectField.widget[0]?.fieldWidget?.title === 'DaData:bank') {
                            return <InputDaDataWrapper
                                isVisible={field.type?.title !== 'hidden'}
                                errors={_getErrors(field.projectField.name)}
                                key={field.id}
                                id={field.projectField.name}
                                register={register}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                setValue={setValue}
                                isDatetime={false}
                                label={field.projectField.label} isRequired={true}/>
                        }
                        if (field.projectField.projectFieldType.name === 'textarea') {
                            return <TextAreaWrapper
                                isVisible={field.type?.title !== 'hidden'}
                                errors={_getErrors(field.projectField.name)}
                                key={field.id}
                                id={field.projectField.name}
                                register={register}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                label={field.projectField.label}/>
                        }

                        if (field.projectField.projectFieldType.name === 'dropdown') {
                            let options = field.projectField.dropdownVal.map(item => ({
                                value: item.key,
                                label: item.val,
                            }));

                            return <SelectWithFormHook
                                key={field.id}
                                isVisible={field.type?.title !== 'hidden'}
                                attribute={"Deal." + field.projectField.name}
                                control={control}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                errorMessage={_getErrors(field.projectField.name)?.message}
                                value={_getValueForDropdown(options, field.projectField.name)}
                                setValue={(newValue) => {
                                    setValue("Deal." + field.projectField.name, newValue.value)
                                }}
                                options={options}
                                isRequired={true}
                                requiredMessage={'Необходимо выбрать ' + field.projectField.label}
                                label={field.projectField.label}
                                placeholder={field.projectField.label}
                                onChangeCallback={() => {
                                }}/>
                        }

                        if (field.projectField.widget[0]?.fieldWidget?.title === 'inputMask:ru:date') {
                            return <InputDateWrapper
                                control={control}
                                key={field.id}
                                id={field.projectField.name}
                                errors={_getErrors(field.projectField.name)}
                                label={field.projectField.label}
                                readOnly={field.type?.title === 'readonly'}
                                disabled={field.type?.title === 'disabled'}
                                displayValue={_getAttributesByName(field.projectField.name)}
                                isVisible={field.type?.title !== 'hidden'}/>;
                        }

                        for (const {title, mask} of inputMaskFields) {
                            if (field.projectField.widget[0]?.fieldWidget?.title === title) {
                                return renderInputMaskWrapper(field, mask);
                            }
                        }

                        if (field.projectField.projectFieldType.name === 'file-jquery') {
                            return <StyledDropzone
                                text={'Загрузите Документ'}
                                deal={deal}
                                readonly={field.type?.title === 'readonly' || field.type?.title === 'disabled'}
                                token={user.token}
                                onSuccessUpload={() => fetchDeal(deal.id)}
                                key={field.id} blockType={field.projectField.id}
                                projectName={field.projectField.name}/>;
                        }

                        return <InputWrapper
                            isVisible={field.type?.title !== 'hidden'}
                            errors={_getErrors(field.projectField.name)}
                            key={field.id}
                            id={field.projectField.name}
                            register={register}
                            readOnly={field.type?.title === 'readonly'}
                            disabled={field.type?.title === 'disabled'}
                            setValue={setValue}
                            isDatetime={false}
                            label={field.projectField.label} isRequired={true}/>
                    })}
                    {col.buttons.map(button => {
                        return (
                            <a onClick={() => _downloadFile(findReplaceString(button.projectButton.path_url, deal.id))}
                               className={button.projectButton.class} key={button.id}
                               dangerouslySetInnerHTML={{__html: button.projectButton.name}}>
                            </a>
                        );
                    })}
                    {col.textblocks.map(block => {
                        return (
                            <div key={block.id} className={block.projectTextblock.css_class}
                                 dangerouslySetInnerHTML={{__html: block.projectTextblock.text}}/>
                        );
                    })}
                </div>
            );
        })
    }

    if (errorForm.code) {
        return <ErrorComponent message={errorForm.message} code={errorForm.code}/>
    }

    function showModal() {
        let myModal = new Modal(document.getElementById('cancelModal'), {
            keyboard: false
        });
        myModal.show();
    }

    function cancelDeal() {
        setIsLoading(true)
        axiosInstance.post(API_URL + '/deals/cancel', {
            Deal: {
                project_workflow_status_id: backStatusId,
            }
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                id: deal.id,
            }
        })
            .then(response => {
                navigate(`/deal/${deal.id}`)
            })
            .then(() => {
                let myModal = Modal.getInstance(document.getElementById('cancelModal'));
                myModal.hide();
            })
            .catch(errorResponse => {
                errorResponse.response.data.errors.map(error => {
                    console.log(`Deal.${error.name}`)
                    setError(`Deal.${error.name}`, {
                        type: "random",
                        message: error.message,
                    })
                })
            }).finally(() => fetchDeal(deal.id))
    }

    return (
        <div className="bg-light p-5 rounded">
            <h1>Редактировать #{deal.id}</h1>
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title">Информация о сделке</h5>
                </div>
                <div className="card-body">
                <DetailView deal={deal} />
                </div>
            </div>
            <form onSubmit={handleSubmit(handleForm)} className="justify-content-center">
                {detail.statusMetadata && detail.statusMetadata.map(metaData => {
                    return (
                        <div className="card" style={{marginBottom: '20px'}} key={metaData.id}>
                            <div className="card-header">
                                <h5 className="card-title">{metaData.name}</h5>
                            </div>
                            <div className="card-body">
                                {_renderRows(metaData.row)}
                            </div>
                        </div>
                    );
                })}
                {isLoading && (
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                )}
                {!isLoading && (
                    <>
                        {backButton?.id && (
                            <a className="btn btn-secondary" onClick={cancel}>{backButton.value ?? 'Назад'}</a>)}
                        {nextButton?.id && (
                            <button type={'submit'} className="btn btn-primary">{nextButton.value ?? 'Вперед'}</button>
                        )}
                        {cancelButton?.id && (
                            <a className="btn btn-danger" onClick={showModal}>{cancelButton.value ?? 'Отмена'}</a>
                        )}
                    </>
                )}
            </form>
            <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="cancelModalLabel">Отмена изменений</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Вы действительно уверены, что хотите изменить статус сделки
                            на "Отмена"?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                            <button type="button" className="btn btn-danger" onClick={cancelDeal}>Отменить изменения
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealUpdateScreen);

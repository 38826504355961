import React, {memo} from "react";
import InputMask from "react-input-mask";

const InputMaskWrapper = ({id, mask, label, errors, register, onChange=(e) => {}, isRequired = true, readOnly = false, disabled = false, isVisible = false}) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">{label}</label>
            <InputMask id={id} mask={mask}
                       readOnly={readOnly}
                       disabled={disabled}
                       className={"form-control " + (errors ? "is-invalid" : '')}
                       {...register(`Deal.${id}`, {
                           required: isRequired ? `Необходимо заполнить «${label}».` : false
                       })}
                       onChange={onChange} />
            {errors && (
                <div className="invalid-feedback">
                    {errors.message}
                </div>
            )}
        </div>
    );
}

export default memo(InputMaskWrapper);

import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ProductItem from "./Product/ProductItem";
function StatisticScreen({user}) {
    return (
        <div className="bg-light p-5 rounded">
            <h1>Статистика</h1>
            <div>
             </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticScreen);

import React, {memo} from "react";

const TextAreaWrapper = ({
                             id, label, errors, register, onChange = (e) => {
    }, isRequired = true, readOnly = false, disabled = false, isVisible = false
                         }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">{label}</label>
            <textarea id={id}
                      readOnly={readOnly}
                      disabled={disabled}
                      className={"form-control " + (errors ? "is-invalid" : '')}
                      {...register(`Deal.${id}`, {
                          required: isRequired ? `Необходимо заполнить «${label}».` : false
                      })}
                      onChange={onChange}/>
            {errors && (
                <div className="invalid-feedback">
                    {errors.message}
                </div>
            )}
        </div>
    );
}

export default memo(TextAreaWrapper);

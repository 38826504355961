import React, { useEffect, useState } from "react";
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./screens/layouts/Layout";
import LoginScreen from "./screens/auth/LoginScreen";
import HomeScreen from "./screens/home/HomeScreen";
import {Provider as ReduxProvider, useDispatch} from 'react-redux';
import configureStore from "./storage/configureStore";
import axios from "axios";
import { API_URL, TOKEN } from "./utils/consts";
import { updateToken } from "./storage/actions/UserActions";
import DealsScreen from "./screens/deals/DealsScreen";
import DealViewScreen from "./screens/deals/DealViewScreen";
import DealCreateScreen from "./screens/deals/DealCreateScreen";
import OrganizationScreen from "./screens/deals/ProductScreen";
import ProductScreen from "./screens/deals/ProductScreen";
import StatisticScreen from "./screens/deals/StatisticScreen";
import DealUpdateScreen from "./screens/deals/DealUpdateScreen";
import ProtectedRoute from './ProtectedRoute';
import {handleWebSocketMessage} from "./utils/webSocketMessageHandler";
import {connectWebSocket} from "./utils/webSocket";

const store = configureStore();

function App() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        _checkAuth();
    }, []);

    const _checkAuth = () => {
        let token = localStorage.getItem(TOKEN);
        if (token) {
            token = JSON.parse(token);
        }

        if (token) {
            axios
                .get(API_URL + '/users/me', {
                    headers: {
                        Authorization: 'Bearer ' + token.token
                    }
                })
                .then(response => response.data)
                .then(() => {
                    store.dispatch(updateToken(token));
                    setIsAuth(true);
                })
                .catch(() => {
                    localStorage.removeItem(TOKEN);
                    setIsAuth(false);
                })
                .finally(() => {
                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        if (isAuth) {
            const token = JSON.parse(localStorage.getItem(TOKEN));
            connectWebSocket(`${token.id}|${token.token}`, (data) => handleWebSocketMessage(store.dispatch, data));
        }
    }, [isAuth]);

    if (!isLoaded) {
        return (
            <div>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Загрузка...</span>
                </div>
            </div>
        );
    }

    return (
        <ReduxProvider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={
                            <ProtectedRoute isAuth={isAuth}>
                                <HomeScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="login" element={<LoginScreen />} />
                        <Route path="deals" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <DealsScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="deal/:id" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <DealViewScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="deal/:id/update" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <DealUpdateScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="deal/create" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <DealCreateScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="product" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <ProductScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="organization" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <OrganizationScreen />
                            </ProtectedRoute>
                        }/>
                        <Route path="statistic" element={
                            <ProtectedRoute isAuth={isAuth}>
                                <StatisticScreen />
                            </ProtectedRoute>
                        }/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ReduxProvider>
    );
}

export default App;

import React from 'react';

const DetailView = ({deal}) => {
    if (!deal.id) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="col-12" style={{display: "flex"}}>
                    <div style={{marginRight: "1rem"}}><b>Номер сертификата:</b> {deal.contractCode}</div>
                    <div style={{marginRight: "1rem"}}><b>Действует с:</b> {deal.dealInfo.beginAt}
                        <b>по</b> {deal.dealInfo.endAt}</div>
                </div>
            </div>
            <hr/>
            <div className="col-12" style={{display: "flex"}}>
                <div style={{marginRight: "1rem"}}><b>Проект:</b> {deal.dealInfo.project.title}</div>
                <div style={{marginRight: "1rem"}}><b>Продукт:</b> {deal.dealInfo.project.product.title}</div>
                <div style={{marginRight: "1rem"}}><b>Тариф:</b> {deal.dealInfo.project.price.title}</div>
                <div style={{marginRight: "1rem"}}><b>Стоимость:</b> {deal.dealInfo.price} {deal.dealInfo.currency}
                </div>
            </div>
            <hr/>
            <div className="col-12" style={{display: "flex"}}>
                <div style={{marginRight: "1rem"}}><b>Подразделение:</b> {deal.dealInfo.organization.title}</div>
                <div style={{marginRight: "1rem"}}><b>Пользователь:</b> {deal.dealInfo.user.title}</div>
            </div>
            <hr/>
            <div className="col-12" style={{display: "flex"}}>
                <div style={{marginRight: "1rem"}}><b>Клиент ФИО:</b> {deal.dealInfo.client_fio}</div>
                <div style={{marginRight: "1rem"}}><b>Телефон:</b> {deal.dealInfo.client_phone}</div>
                <div style={{marginRight: "1rem"}}><b>Email:</b> {deal.dealInfo.client_email}</div>
            </div>
        </div>
    );
};

export default DetailView;

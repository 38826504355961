import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ProductItem from "./Product/ProductItem";
function ProductScreen({user}) {
    return (
        <div className="bg-light p-5 rounded">
            <h1>Список продуктов</h1>
            <div>
                <ProductItem src={"https://gulliver.forest-save.ru/images/dist/hero-logo-1.svg"} title={"Сохрани лес"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={"https://dsauto.pro/images/src/logo.png"} title={"Независимая гарантия"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={"https://sovetnikonline.ru/img/logo.png"} title={"Советник онлайн"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={"https://drive-help.ru/images/dist/footer_logo.svg"} title={"Фри Ассист"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={"https://zkh-service.ru/images/tild3630-3838-4663-b139-303364373533__logo.png"} title={"Сохрани лес"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={"https://lk.digitalseller.pro/assets/a90e1ad/img/logo.png"} title={"Сохрани лес"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={"https://cosmovisa.com/site/img/logo.svg"} title={"cosmovisa.com"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={""} title={"Кредитный попошник"} description={"Описание Описание Описание Описание"}/>
                <ProductItem src={""} title={"Соц попошник"} description={"Описание Описание Описание Описание"} />
                <ProductItem src={""} title={"HR попошник"} description={"Описание Описание Описание Описание"}/>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductScreen);

import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {API_URL} from "../../utils/consts";
import {useForm} from "react-hook-form";
import {useFetchPrices, useFetchProducts, useFetchProjects} from "../../hooks/useFetchDictionary";
import SelectWithFormHook from "../../components/selectWithFormHook/SelectWithFormHook";
import InputWrapper from "../../components/inputWrapper/InputWrapper";
import {useNavigate} from "react-router-dom";
import {userCan} from "../../utils/helpers";
import { useDebounce } from "use-debounce";
import axiosInstance from "../../utils/apiCalls";

const defaultValue = {
    Deal: {
        project_id: null,
        project_product_id: null,
        project_price_id: null,
        project_contract_id: null,
        project_price_validity: null,
        project_organization_id: null,
        vsk_credit_sum: null,
        vsk_insured_amount: null,
        vsk_gap_insured_amount_kasko: null,
        vsk_gap_insured_amount: null,
        insured_amount: null,
        price: null,
    }
};

function DealViewScreen({user}) {
    const navigate = useNavigate();
    const [isLoad, setIsLoad] = useState(false);
    // const [projectSettings, setProjectSettings] = useState({});
    const [priceSettings, setPriceSettings] = useState({});

    const [organization, setOrganization] = useState({});
    const [organizations, setOrganizations] = useState([]);

    const [contract, setContract] = useState({});
    const [contracts, setContracts] = useState([]);

    const [category, setCategory] = useState({});
    const [categories, setCategories] = useState([]);

    const [project, setProject] = useState({});
    const [projects] = useFetchProjects(API_URL + '/dictionary/projects');

    const [product, setProduct] = useState({});
    const [products] = useFetchProducts(API_URL + '/dictionary/products', project.value, [project], null);

    const [price, setPrice] = useState({});
    const [prices] = useFetchPrices(
        API_URL + '/dictionary/prices',
        project.value,
        product.value,
        [project, product],
        null
    );

    const [insuranceAmount, setInsuranceAmount] = React.useState(0);
    const [debouncedValue] = useDebounce(insuranceAmount, 500);

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        control
    } = useForm({
        defaultValue,
    })

    const _resetOnChangeProject = () => {
        setProduct({})
        setPrice({})
        setOrganizations([])
        setOrganization({})
        setContracts([])
        setContract({})
        setCategories([])
        setCategory({})
        setPriceSettings({})
        setValue("Deal.price", null);
    }

    const handleForm = (data) => {
        setIsLoad(true)
        axiosInstance.post(API_URL + '/deals/create', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then(response => response.data)
            .then(responseJson => {
                console.log(responseJson.success)
                if (responseJson.success) {
                    navigate(`/deal/${responseJson.data.id}/update`)
                }
            }).catch(errorResponse => {
                console.log(errorResponse);
        }).finally(() => {
            setIsLoad(true)
        })
    }

    const _projectSettings = (projectId) => {
        setIsLoad(true);

        axiosInstance.get(API_URL + '/deals/project-settings', {
            params: {
                projectId,
            }
        })
            .then(response => response.data)
            .then(responseData => {
                // setProjectSettings(responseData.data.settings)
            }).catch(errorResponse => {
            console.warn(errorResponse)
        }).finally(() => {
            setIsLoad(false);
        })
    }
    const _priceSettings = (priceId) => {
        setIsLoad(true);
        axiosInstance.get(API_URL + '/deals/price-settings', {
            params: {
                projectId: project.value,
                priceId,
            }
        })
            .then(response => response.data)
            .then(responseData => {
                setPriceSettings(responseData.data?.settings)
                setOrganizations(responseData.data.organizations.map(item => ({
                    value: item.id.toString(),
                    label: item.title,
                })));
            }).catch(errorResponse => {
            console.warn(errorResponse)
        }).finally(() => {
            _fetchCategories(priceId);
            _getDealCost(priceId);
        })
    }

    const _fetchContracts = (organizationId) => {
        axiosInstance.get(API_URL + '/deals/contracts', {
            params: {
                organizationId,
            }
        })
            .then(response => response.data)
            .then(responseData => {
                setContracts(responseData.data.contracts.map(item => ({
                    value: item.id.toString(),
                    label: item.title,
                })));
            }).catch(errorResponse => {
            console.warn(errorResponse)
        }).finally(() => {
            setIsLoad(false);
        })
    }

    const _fetchCategories = (priceId) => {
        setIsLoad(true);
        axiosInstance.get(API_URL + '/deals/price-validity', {
            params: {
                projectId: project.value,
                priceId,
            }
        })
            .then(response => response.data)
            .then(responseData => {
                setCategories(responseData.data.map(item => ({
                    value: item.id.toString(),
                    label: item.label,
                })));
            }).catch(errorResponse => {
            console.warn(errorResponse)
        }).finally(() => {
            setIsLoad(false);
        })
    }

    const _getDealCost = (priceId, validity = null) => {
        setIsLoad(true);
        axiosInstance.get(API_URL + '/deals/deal-price', {
            params: {
                projectId: project.value,
                priceId,
                validity,
            }
        })
            .then(response => response.data)
            .then(responseData => {
                setValue('Deal.price', responseData?.data ?? 0)
            }).catch(errorResponse => {
            console.warn(errorResponse)
        }).finally(() => {
            setIsLoad(false);
        })
    }

    const _getDealCostWithValidityCategory = (insure_amount, validity = null) => {
        setIsLoad(true);
        axiosInstance.get(API_URL + '/deals/deal-price-with-insured-amount', {
            params: {
                project_id: project.value,
                price_id: price.value,
                validity,
                insured_amount: insure_amount
            }
        })
            .then(response => response.data)
            .then(responseData => {
                console.log(responseData?.data ?? 0)
                setValue('Deal.price', responseData?.data ?? 0)
            }).catch(errorResponse => {
            console.warn(errorResponse)
        }).finally(() => {
            setIsLoad(false);
        })
    }

    useEffect(() => {
        if (debouncedValue) {
            _getDealCostWithValidityCategory(debouncedValue, category.value)
        }
    }, [debouncedValue]);

    const _changeInsureAmount = (event) => {
        setInsuranceAmount(event.target.value);
    }

    return (
        <div className="bg-light p-5 rounded">
            <h1>Новая сделка</h1>
            <form onSubmit={handleSubmit(handleForm)} className="row justify-content-center">
                <div className="col-4">
                    <SelectWithFormHook
                        attribute={"Deal.project_id"}
                        control={control}
                        isVisible={true}
                        errorMessage={errors?.Deal?.project_id?.message}
                        value={project}
                        setValue={setProject}
                        options={projects}
                        label={'Проект'}
                        requiredMessage={'Project is required'}
                        isRequired={true}
                        placeholder={'Выбрать проект'}
                        onChangeCallback={(newValue) => {
                            _resetOnChangeProject();
                            _projectSettings(newValue.value)
                        }}/>

                    <SelectWithFormHook
                        isVisible={project.value > 0}
                        attribute={"Deal.project_product_id"}
                        control={control}
                        errorMessage={errors?.Deal?.project_product_id?.message}
                        value={product}
                        setValue={setProduct}
                        options={products}
                        isRequired={true}
                        requiredMessage={'Product is required'}
                        label={'Продукт'}
                        placeholder={'Выбрать продукт'}
                        onChangeCallback={() => {
                            setPrice({});
                            setOrganizations([])
                            setOrganization({})
                            setContracts([])
                            setContract({})
                        }}/>

                    <SelectWithFormHook
                        isVisible={(project.value > 0 || product.value > 0)}
                        attribute={"Deal.project_price_id"}
                        control={control}
                        errorMessage={errors?.Deal?.project_price_id?.message}
                        value={price}
                        setValue={setPrice}
                        options={prices}
                        isRequired={true}
                        requiredMessage={'Price is required'}
                        label={'Тариф'}
                        placeholder={'Выбрать тариф'}
                        onChangeCallback={(newValue) => _priceSettings(newValue.value)}/>


                    <SelectWithFormHook
                        isVisible={(organizations.length > 0)}
                        attribute={"Deal.project_organization_id"}
                        control={control}
                        errorMessage={errors?.Deal?.project_organization_id?.message}
                        value={organization}
                        setValue={setOrganization}
                        options={organizations}
                        isRequired={true}
                        requiredMessage={'Organization is required'}
                        label={'Юридическое лицо'}
                        placeholder={'Выбрать юридическое лицо'}
                        onChangeCallback={(newValue) => _fetchContracts(newValue.value)}/>

                    <SelectWithFormHook
                        isVisible={(contracts.length > 0)}
                        attribute={"Deal.project_contract_id"}
                        control={control}
                        errorMessage={errors?.Deal?.project_contract_id?.message}
                        value={contract}
                        setValue={setContract}
                        options={contracts}
                        isRequired={true}
                        requiredMessage={'Organization is required'}
                        label={'Агентский Договор'}
                        placeholder={'Выбрать агентский Договор'}
                        onChangeCallback={(newValue) => {
                        }}/>
                    <hr/>

                    <SelectWithFormHook
                        isVisible={(categories.length > 0)}
                        attribute={"Deal.project_price_validity"}
                        control={control}
                        errorMessage={errors?.Deal?.project_price_validity?.message}
                        value={category}
                        setValue={setCategory}
                        options={categories}
                        isRequired={true}
                        requiredMessage={'Category is required'}
                        label={(priceSettings?.show_deal_validity !== "1") ? priceSettings?.show_deal_validity : 'Срок действия'}
                        placeholder={'Выбрать категорию'}
                        onChangeCallback={(newValue) => _getDealCost(price.value, newValue.value)}/>


                    <InputWrapper
                        isVisible={priceSettings.vsk_creditSum}
                        errors={errors.Deal?.vsk_credit_sum}
                        id={'vsk_credit_sum'}
                        register={register}
                        label={'Сумма кредита, руб.'}/>

                    <InputWrapper
                        isVisible={priceSettings.vsk_creditSum}
                        errors={errors.Deal?.vsk_insured_amount}
                        id={'vsk_insured_amount'}
                        register={register}
                        readOnly={true}
                        label={'Страховая сумма, руб.'}/>

                    <InputWrapper
                        isVisible={priceSettings.vskGap_insuredAmountKasko}
                        errors={errors.Deal?.vsk_gap_insured_amount_kasko}
                        id={'vsk_gap_insured_amount_kasko'}
                        register={register}
                        label={'Страховая сумма КАСКО, руб.'}/>

                    <InputWrapper
                        isVisible={priceSettings.vskGap_insuredAmountKasko}
                        errors={errors.Deal?.vsk_gap_insured_amount}
                        id={'vsk_gap_insured_amount'}
                        readOnly={true}
                        register={register}
                        label={'Страховая сумма GAP, руб.'}/>

                    <InputWrapper
                        isVisible={priceSettings?.show_insurance_remuneration}
                        errors={errors.Deal?.insured_amount}
                        id={'insured_amount'}
                        register={register}
                        onChange={_changeInsureAmount}
                        label={'Страховая сумма руб.'}/>

                    <InputWrapper
                        isVisible={true}
                        id={'price'}
                        errors={errors.Deal?.price}
                        readOnly={true}
                        register={register}
                        label={'Стоимость сертификата'}/>

                    {userCan(user.roles, 'admin') && (<div className="form-check">
                        <input
                            id="isTestCheckBox"
                            type="checkbox"
                            onChange={event => setValue("Deal.is_test", event.target.checked ? 1 : 0)}
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="isTestCheckBox">
                            Тестовая?
                        </label>
                    </div>)}
                    <hr/>

                    <div className="col-4">
                        {!isLoad && <button type="submit" className="btn btn-primary">Создать</button>}
                        {isLoad &&
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealViewScreen);

import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

function ProductItem(props) {
    return (

        <div className="card mb-3 s.product ">
            <div className="row g-0">
                <div className="s.logo col-md-4" style={{padding:"2rem"}} >
                        <img src={props.src} className="img-fluid rounded-start" alt="..."  style={{marginBottom: "0px !important",height:"100%"}}/>
                </div>

                <div className="col-md-8 ">
                    <div className="card-body  ">
                        <h5 className="card-title">{props.title}</h5>
                        <p className="card-text">{props.description}</p>
                        <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small>
                        </p>
                    </div>
                    <div className="card-footer mt-auto">
                        <button className="btn btn-outline-primary">Заявка на подключение</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ProductItem;

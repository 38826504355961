import React, {useCallback, useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {NavLink, useParams} from "react-router-dom";
import {fetchDeal} from "../../services/dealService";
import ErrorComponent from "../../components/error/ErrorComponent";
import {getBadgeType} from "../../utils/helpers";
import DetailView from "../../components/deal/DetailView";

function DealViewScreen({user}) {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [deal, setDeal] = useState({});
    const [error, setError] = useState({});

    const loadDeal = useCallback((id) => {
        setIsLoading(true);
        window.scrollTo({top: 0});
        fetchDeal(id)
            .then(response => response.data)
            .then(response => {
                setDeal(response.data)
            })
            .catch(errorResponse => {
                setError({
                    message: errorResponse.response.data.errors.message,
                    code: errorResponse.response.status
                });
            })
            .finally(() => setIsLoading(false));
    }, [user.token]);

    useEffect(() => {
        loadDeal(id);
    }, [loadDeal, id]);

    if (error.code) {
        return <ErrorComponent message={error.message} code={error.code}/>;
    }

    if (isLoading) {
        return (
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 col-md-3 bg-light p-5 rounded">
                <h1>Сделка #{deal.id}</h1>
                <p>
                    Статус: <span className={getBadgeType(deal.statusOptions.label)}>{deal.status}</span>
                </p>
            </div>
            <div className="col-12 col-md-9 bg-light p-5 rounded">
                <div className="col-12 col-md-9 bg-light p-5 rounded">
                    <DetailView deal={deal}/>
                </div>
                <div className="col-12">
                    <NavLink to={`/deal/${deal.id}/update`}>
                        <br/>
                        <a href="#" className="btn btn-primary">Редактировать</a>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealViewScreen);

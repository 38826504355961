const WS_URL = 'ws://localhost:9807';

let socket;
let reconnectInterval;

export function connectWebSocket(token, onMessage) {
    clearInterval(reconnectInterval);

    socket = new WebSocket(WS_URL);

    socket.onopen = () => {
        console.log("WebSocket connected");

        socket.send(JSON.stringify({
            action: "ID",
            data: {
                token: token,
                from: "frontend"
            }
        }));
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessage(data);
    };

    socket.onclose = () => {
        console.log("WebSocket closed, attempting to reconnect...");

        reconnectInterval = setInterval(() => {
            connectWebSocket(token, onMessage);
        }, 10000);
    };

    socket.onerror = (error) => {
        console.error("WebSocket error", error);
        socket.close();
    };
}

export function closeWebSocket() {
    clearInterval(reconnectInterval);
    if (socket) socket.close();
}

import {useEffect, useState} from "react";
import axiosInstance from "../utils/apiCalls";

export const useFetchProjects = (url, deps = [], token = null) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axiosInstance.get(url)
            .then(response => response.data)
            .then(response => setData(response.data.map(item => ({
                value: item.id.toString(),
                label: item.title,
            }))))
            .catch(setError)
            .finally(() => setLoading(false));
    }, [url, token, ...deps]);

    return [data, error, loading];
};

export const useFetchProducts = (url, projectId, deps = [], token = null) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (projectId) {
            setLoading(true);
            axiosInstance.get(url, {
                params: {
                    projectId
                },
            })
                .then(response => response.data)
                .then(response => setData(response.data.map(item => ({
                    value: item.id.toString(),
                    label: item.title,
                }))))
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [url, ...deps]);

    return [data, error, loading];
};

export const useFetchPrices = (url, projectId, productId = null, deps = [], token = null) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (projectId || productId) {
            setLoading(true);
            axiosInstance.get(url, {
                params: {
                    projectId,
                    productId,
                },
            })
                .then(response => response.data)
                .then(response => setData(response.data.map(item => ({
                    value: item.id.toString(),
                    label: item.title,
                }))))
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [url, ...deps]);

    return [data, error, loading];
};

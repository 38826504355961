import React, {memo, useState} from "react";
import {Controller} from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

const InputDateWrapper = ({
                              control,
                              id,
                              label,
                              errors,
                              displayValue,
                              readOnly = false,
                              disabled = false,
                              isVisible = false
                          }) => {

    const [tempValue, setTempValue] = useState(moment(displayValue,'DD.MM.YYYY').format('X') * 1000)

    if (!isVisible) {
        return null;
    }

    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">{label}</label>
            <Controller
                control={control}
                name={`Deal.${id}`}
                disabled={disabled}
                render={({field}) => (
                    <DatePicker
                        placeholderText='Указать дату'
                        onChange={(date) => {
                            setTempValue(date);
                            field.onChange(moment(date).format('DD.MM.YYYY'))
                        }}
                        disabled={disabled}
                        readonly={readOnly}
                        selected={tempValue}
                        dateFormat="dd.MM.yyyy"
                        className={'form-control'}
                    />
                )}
            />
            {errors?.message && (
                <div className="invalid-feedback" style={{display: 'block'}}>
                    {errors.message}
                </div>
            )}
        </div>
    );
}

export default memo(InputDateWrapper);

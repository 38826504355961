import { SET_EXPORT_LIST, SET_EXPORT_COUNTS, SET_EXPORT_ITEMS } from "../actions/ExportActions";

const initialState = {
    exportList: [],
    exportCount: 0,
    exportDoneCount: 0,
    exportItems: []
};

const exportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPORT_LIST:
            return { ...state, exportList: action.payload };
        case SET_EXPORT_COUNTS:
            return {
                ...state,
                exportCount: action.payload.count,
                exportDoneCount: action.payload.countDone
            };
        case SET_EXPORT_ITEMS:
            return { ...state, exportItems: action.payload };
        default:
            return state;
    }
};

export default exportReducer;
